// react
import React, { VFC, useState, useEffect } from 'react';

// packages
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// redux
import { selectLogo, selectUrlProduct } from 'store/modules/config/selectors';

// hooks
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { useTheme } from 'styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// styles
import styles from 'components/Header/V1/styles';

// utils
import { getProductValue } from 'store/utils/hooks';

// types
import { ProductEnum } from 'types';

const Header: VFC = function () {
  // redux
  const configLogo = useAppSelector(selectLogo);
  const product = getProductValue(useAppSelector(selectUrlProduct));

  // hooks
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

  // state
  const [logo, setLogo] = useState<string>('');

  // constants
  const isCertificatePage = history.location.pathname.includes('/certificate/');
  const isRaas =
    product === ProductEnum.enhancedrefundprogram ||
    product === ProductEnum.optionalRAAS;

  useEffect(() => {
    if (configLogo) return setLogo(configLogo);

    if (isRaas) return;

    if (product) return setLogo(`/logo/${product}.png`);

    return setLogo('/logo/default.png');
  }, [configLogo, isRaas, product]);

  return (
    <Box
      padding={0}
      sx={{
        ...styles.container,
        ...styles.headerContainer,
        ...styles.imageContainer,
        ...(isRaas && {
          minHeight: 0,
          paddingTop: '100px',
        }),
        ...(isCertificatePage && {
          backgroundColor: '#f6f7f7',
          display: 'flex',
          justifyContent: 'center',
          // offset the google translate div
          marginTop: '-35px',
          paddingBottom: '35px',
          paddingTop: '35px',
          width: '100%',
        }),
      }}
    >
      {(configLogo || !isRaas) && (
        <Grid
          container
          spacing={0}
          maxWidth={isMobile ? 'sm' : 'xs'}
          sx={isMobile ? styles.mobileNavbarContainer : styles.navbarContainer}
        >
          <img
            src={logo}
            style={{
              ...styles.logo,
              ...(isMobile && { ...styles.mobileLogo }),
              ...(isCertificatePage && {
                width: '300px',
              }),
            }}
            alt="Logo"
          />
        </Grid>
      )}
    </Box>
  );
};

export default Header;

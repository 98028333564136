import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { ProductEnum } from 'types';
import { getProductSlug } from 'utils/helpers';
import { getProductValue } from 'store/utils/hooks';
import { selectUrlProduct } from '../config/selectors';
import { FlowType } from '../config/types';
import api from './api';

export const sendEmailToken = createAsyncThunk(
  'email/sendEmailToken',
  async (email: string, { getState, fulfillWithValue }) => {
    const state = getState() as RootState;
    const product = getProductValue(selectUrlProduct(state));
    const productSlug = getProductSlug(product || ProductEnum.fanshield);
    const optionalRAASProductSlug = getProductSlug(ProductEnum.optionalRAAS);
    const type: FlowType = state.configFileReducer.flow;

    try {
      const response = await api.sendEmailToken({
        email,
        products:
          product === ProductEnum.enhancedrefundprogram
            ? [productSlug, optionalRAASProductSlug]
            : [productSlug],
        type: type === 'refund' ? 'claim' : type,
      });
      return response.data;
    } catch (e) {
      return fulfillWithValue(
        'A login link has been sent to this email if it exists and any active orders are found.'
      );
    }
  }
);

/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-template-curly-in-string */

// types
import type { ProductOrder } from 'store/modules/orders/types';
import type { ConfigFileTypes, ConfigStep, ConfigTheme } from 'types';

export const raasSteps: ConfigStep[] = [
  {
    name: 'Event Info',
    backButtonText: '',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Additional Information Needed',
          footer: '',
          description:
            'To begin processing your refund request, we need a little more information. Thank you.',
        },
      },
      {
        component: 'InputPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 4,
          },
          name: 'eventName',
          required: true,
          defaultValue: '${GLOBAL.ordersReducer.selectedOrder.event.name}',
        },
        verbiages: {
          description:
            'What is the name of the event? (This name needs to match the supporting documents you are providing)',
          placeholder: 'Enter Event Name',
          reviewPageTitle: 'Event Name',
        },
      },
      {
        component: 'InputPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 4,
          },
          name: 'location',
          required: true,
          defaultValue: '${GLOBAL.ordersReducer.selectedOrder.event.location}',
        },
        verbiages: {
          description:
            'Where is the event located? (This location needs to match the supporting documents you are providing)',
          placeholder: 'Enter City',
          reviewPageTitle: 'Location',
        },
      },
      {
        component: 'DatePanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 4,
          },
          name: 'date',
          required: true,
          defaultValue: '${GLOBAL.ordersReducer.selectedOrder.event.date}',
        },
        verbiages: {
          description:
            'What is the event date? (This date needs to match the supporting documents you will provide.)',
          reviewPageTitle: 'Event Date',
        },
      },
      {
        component: 'DecisionPanel',
        verbiages: {
          description: 'Was the event ${LOCAL.eventName} cancelled?',
          courtesyMessage:
            'Please note: When an event organizer cancels the event, it voids your protection plan per the Terms and Conditions. Please contact your event organizer for further details.',
          confirm: 'YES',
          reject: 'NO',
        },
        props: {
          displayChildrenOnYes: false,
          name: 'event_decision',
          childrenComponents: [
            {
              component: 'DecisionPanel',
              verbiages: {
                description: 'Did you attend ${LOCAL.eventName}?',
                courtesyMessage: '',
                confirm: 'YES',
                reject: 'NO',
              },
              props: {
                displayChildrenOnYes: true,
                displayCourtesyMessageOnYes: false,
                name: 'attendance_decision',
                childrenComponents: [
                  {
                    component: 'DatePanel',
                    props: {
                      reviewPageGrid: {
                        xs: 12,
                        sm: 4,
                      },
                      name: 'attendance',
                      required: true,
                      defaultValue: {
                        arrival_date: '',
                        departure_date: '',
                      },
                      displayTime: true,
                    },
                    verbiages: {
                      description:
                        'Date and time of when you attended the event',
                      reviewPageTitle: 'Attendance Date',
                      errorMessage: 'Attendance Date is required',
                    },
                  },
                ],
              },
            },
            {
              component: 'AddressPanel',
              props: {
                reviewPageGrid: {
                  xs: 12,
                  sm: 4,
                },
                name: 'billingAddress',
                required: true,
                defaultValue: {
                  address_1: '${GLOBAL.customerReducer.customer.address_1}',
                  address_2: '${GLOBAL.customerReducer.customer.address_2}',
                  city: '${GLOBAL.customerReducer.customer.city}',
                  state: '${GLOBAL.customerReducer.customer.state}',
                  zip_code: '${GLOBAL.customerReducer.customer.zip_code}',
                  country: '${GLOBAL.customerReducer.customer.country}',
                  phone: '${GLOBAL.customerReducer.customer.phone}',
                },
              },
              verbiages: {
                description: 'Billing Information associated with purchase.',
                reviewPageTitle: 'Billing Address',
              },
            },
            {
              component: 'FileUploadPanel',
              props: {
                reviewPageGrid: {
                  xs: 12,
                },
                name: 'receiptUpload',
                defaultValue: [],
                required: false,
                document_type: 'R',
              },
              verbiages: {
                description:
                  'Now, to process your refund request would you please upload your Order Purchase Receipt here. Important: we need the receipt from your original purchase, not the ticket itself.',
                contactInfoText:
                  'Uploading required documentation expedites the refund process',
                noFileUploaded: 'No file(s) uploaded',
                reviewPageTitle: 'Supporting Documentation',
                acceptance:
                  'We can only accept the following file formats: .jpeg, .png, .pdf, and .mp4',
              },
            },
            {
              component: 'StepSubmitButton',
              verbiages: {
                text: 'Next',
                allCompleteText: 'Update',
              },
            },
          ],
        },
      },
    ],
  },
  {
    name: 'Covered Reasons',
    backButtonText: 'Event Info',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          description:
            'Below is a list of covered reasons. Please select the one (1) scenario that prevented you from attending your event.',
          title: 'What Kept You From Attending?',
          footer: '',
        },
      },
      {
        component: 'PerilsPanel',
        props: {
          required: true,
          name: 'peril',
        },
        verbiages: {
          reviewPageTitle: 'Covered Reason',
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Documents',
    backButtonText: 'Covered Reasons',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Nearly Finished!  Just Need a Few More Details.',
        },
      },
      {
        component: 'TextAreaPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 4,
            md: 5,
          },
          name: 'statement',
          required: true,
        },
        verbiages: {
          description:
            'Please include names and relationships of all the ticket holders in your Personal Statement below.',
          placeholder: 'Personal Statement',
          reviewPageTitle: 'Personal Statement',
        },
      },
      {
        component: 'DatePanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 3,
            md: 2,
          },
          name: 'incidentDate',
          required: true,
        },
        verbiages: {
          description:
            'On what date did your incident occur? (Must match supporting documentation you are providing.)',
          reviewPageTitle: 'Incident Date',
        },
      },
      {
        component: 'FileUploadPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 5,
          },
          name: 'incidentUpload',
          defaultValue: [],
          required: false,
          document_type: 'SD',
        },
        verbiages: {
          description:
            'Please upload the supporting documentation for your specific refund request reason based on the required documentation defined in the Terms & Conditions provided on the site of your purchase.',
          contactInfoText:
            'Uploading required documentation expedites the refund process',
          noFileUploaded: 'No file(s) uploaded',
          reviewPageTitle: 'Supporting Documentation',
          acceptance:
            'We can only accept the following file formats: .jpeg, .png, .pdf, and .mp4',
          footer:
            'Typical documentation required: ${GLOBAL.formsReducer.forms.step2.peril.documents_description}',
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Review',
    backButtonText: 'Documents',
    fields: [
      {
        component: 'ReviewPanel',
        verbiages: {},
      },
    ],
  },
  {
    name: 'ThankYouPage',
    backButtonText: '',
    fields: [
      {
        component: 'ThankYouPage',
        verbiages: {},
      },
    ],
  },
];

export const raasSubmissionMapping = {
  customer: {
    first_name: '${GLOBAL.customerReducer.customer.first_name}',
    last_name: '${GLOBAL.customerReducer.customer.last_name}',
    email: '${GLOBAL.customerReducer.customer.email}',
    address_line_1:
      '${GLOBAL.formsReducer.forms.step1.billingAddress_address_1}',
    address_line_2:
      '${GLOBAL.formsReducer.forms.step1.billingAddress_address_2}',
    city: '${GLOBAL.formsReducer.forms.step1.billingAddress_city}',
    state: '${GLOBAL.formsReducer.forms.step1.billingAddress_state}',
    zip_code: '${GLOBAL.formsReducer.forms.step1.billingAddress_zip_code}',
    country: '${GLOBAL.formsReducer.forms.step1.billingAddress_country}',
    phone_number: '${GLOBAL.formsReducer.forms.step1.billingAddress_phone}',
  },
  event_name: '${GLOBAL.formsReducer.forms.step1.eventName}',
  event_location: '${GLOBAL.formsReducer.forms.step1.location}',
  event_decision: '${GLOBAL.formsReducer.forms.step1.event_decision}',
  event_date: '${GLOBAL.formsReducer.forms.step1.date}',
  incident_date: '${GLOBAL.formsReducer.forms.step3.incidentDate}',
  attendance_decision: '${GLOBAL.formsReducer.forms.step1.attendance_decision}',
  arrival_date: '${GLOBAL.formsReducer.forms.step1.attendance_arrival_date}',
  departure_date:
    '${GLOBAL.formsReducer.forms.step1.attendance_departure_date}',
  statement: '${GLOBAL.formsReducer.forms.step3.statement',
  files: [
    '${GLOBAL.formsReducer.forms.step1.receiptUpload}',
    '${GLOBAL.formsReducer.forms.step3.incidentUpload}',
  ],
  peril: '${GLOBAL.formsReducer.forms.step2.peril.id}',
  additional_comment: 'None Provided.',
};

export const raasTheme: ConfigTheme = {
  palette: {
    primary: {
      main: '#00a3e0',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
    },
  },
};

export const raasGeneralConfig: ConfigFileTypes = {
  theme: raasTheme,
  steps: [],
  submissionMapping: {},
  translationOverride: {
    general: {},
    product: {},
    underwriter: {},
  },
  links: {
    helpUrl: 'https://protecht.com/enhanced-refund-program-faq/',
    helpEmail: 'mailto:help@requestmyrefund.com',
    privacyPolicyUrl: 'https://protecht.com/privacy-policy/',
    contactUsUrl: 'https://www.fanshield.com/contact',
    termsAndConditions: 'https://protecht.com/enhanced-refund-program-terms/',
    helpPhone: '8448494827',
  },
};

const raasProdOrder: ProductOrder[] = [
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20C',
    reference_id: '#1234567',
    created: new Date().toISOString(),
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    currency: 'USD',
    order_premium_total: '9.99',
    order_premium_total_literal: 'USD 9.99',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C3',
      first_name: 'Billy',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '123 Test St',
      address_2: '',
      city: 'Newport Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92663',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
        image_url: null,
        reference_url: null,
        claim_status: '',
        status: 'On Hold',
        issued: '2022-06-02T16:27:38.635939Z',
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: '2022-12-27T12:00:00.000000Z',
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB3',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E1',
        name: 'Colorado Avalanche WinCraft 2022 Stanley Cup Champions',
        description: '3 x 5 1-Sided Deluxe Flag',
        reference_number: 'some_reference_number',
        unit_cost: '42.99',
        unit_cost_literal: 'USD 42.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
        image_url: null,
        reference_url: null,
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          event_interruption: 'true',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: '2022-12-27T12:00:00.000000Z',
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB4',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
        name: 'Colorado Rockies Nike Wordmark Legend T-Shirt',
        description: 'Black Size: XL',
        reference_number: 'some_reference_number',
        unit_cost: '42.99',
        unit_cost_literal: 'USD 42.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '629E9A7F-A276-4089-BDEB-D06B1F1170AA',
        image_url: null,
        reference_url: null,
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: '2022-12-27T12:00:00.000000Z',
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB5',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
        name: 'Colorado Rockies Nike Wordmark Legend T-Shirt',
        description: 'Black Size: L',
        reference_number: 'some_reference_number',
        unit_cost: '50.00',
        unit_cost_literal: 'USD 50.00',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '629E9A7F-A276-4089-BDEB-D06B1F1170AA',
        image_url: null,
        reference_url: null,
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: '2022-12-27T12:00:00.000000Z',
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
    ],
    impressions_count: 1,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20F',
    reference_id: '#456789790',
    created: new Date(Date.now() - 864e5).toISOString(),
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '9.99',
    order_premium_total_literal: 'USD 9.99',
    currency: 'USD',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C5',
      first_name: 'Lancette',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '895 Test St',
      address_2: '',
      city: 'Another Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92668',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB7',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E7',
        name: 'Colorado Rockies New Era 2022 City Connect 59FIFTY Fitted Hat ',
        description: 'White Size: 7 1/8',
        reference_number: 'some_reference_number',
        unit_cost: '45.99',
        unit_cost_literal: 'USD 45.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '326E9AED-E796-446D-9985-BBCD65FC7754',
        image_url: 'coolimage.com',
        reference_url: 'mycoolitem.com/1/',
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: '2022-8-08 12:01:00.123456',
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        look_period: new Date(
          new Date().getTime() - 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: '2022-12-27T12:00:00.000000Z',
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
    ],
    impressions_count: 1,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20F',
    reference_id: '#456789790',
    created: '2022-11-27T16:27:38.635939Z',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '9.99',
    order_premium_total_literal: 'USD 9.99',
    currency: 'USD',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C5',
      first_name: 'Lancette',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '895 Test St',
      address_2: '',
      city: 'Another Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92668',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB7',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E7',
        name: 'Colorado Rockies New Era 2022 City Connect 59FIFTY Fitted Hat ',
        description: 'White Size: 7 1/8',
        reference_number: 'some_reference_number',
        unit_cost: '45.99',
        unit_cost_literal: 'USD 45.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '326E9AED-E796-446D-9985-BBCD65FC7754',
        image_url: 'coolimage.com',
        reference_url: 'mycoolitem.com/1/',
        claim_status: '',
        status: 'Canceled',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: '2022-8-08 12:01:00.123456',
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: '2022-12-27T12:00:00.000000Z',
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
    ],
    impressions_count: 1,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20F',
    reference_id: '#456789790',
    created: '2022-11-27T16:27:38.635939Z',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '9.99',
    order_premium_total_literal: 'USD 9.99',
    currency: 'USD',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C5',
      first_name: 'Lancette',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '895 Test St',
      address_2: '',
      city: 'Another Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92668',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB7',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E7',
        name: 'Colorado Rockies New Era 2022 City Connect 59FIFTY Fitted Hat ',
        description: 'White Size: 7 1/8',
        reference_number: 'some_reference_number',
        unit_cost: '45.99',
        unit_cost_literal: 'USD 45.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '326E9AED-E796-446D-9985-BBCD65FC7754',
        image_url: 'coolimage.com',
        reference_url: 'mycoolitem.com/1/',
        claim_status: 'Initiated',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: '2022-8-08 12:01:00.123456',
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: '2022-12-27T12:00:00.000000Z',
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
    ],
    impressions_count: 1,
  },
];

export const raasOrders = {
  count: 2,
  next: '[https://newdomain.protecht.com/raas/orders/?limit=20&offset=20](https://newdomain.protecht.com/raas/orders/?limit=20&offset=20)',
  previous: null,
  items: raasProdOrder,
};

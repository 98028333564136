/* eslint-disable no-template-curly-in-string */
export default {
  appComponent: {
    title: 'Continue With Your Unfinished request?',
    appTitle: 'Recharge Form',
    footer: {
      title: 'Need to Start ',
      disclaimer: '',
    },
  },
  filePage: {
    title: 'Update Payment Information Request',
    contactInfo: {
      description: 'help@requestmyrefund.com',
      introVerbiage:
        'That’s okay! With a little assistance from you, we should be able to find your information. If you need help, please contact ',
    },
  },
  orderSelectionPage: {
    consentText:
      'By checking this box you consent to the processing of your personal information under our privacy policy (available below).',
    button: 'Complete Payment',
    noOrders: 'No orders found.',
    rules: {
      row1: '',
      row2: '',
    },
    header: {
      row2: '',
    },
    itemTable: {
      header: 'Covered Amount',
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  reviewPage: {
    header: {
      description:
        'Below, please review the information you provided for accuracy. This information will successfully complete your purchase and help us expedite the process. Once you confirm the information is correct, please click the "Confirm Purchase" button.',
    },
    sign: {
      button: 'Confirm Purchase',
      cancel: '',
      note: '',
      claims: {
        text: '',
        footer: '',
        floridaText: '',
        link: {
          text: {
            row2: '',
          },
        },
      },
    },
    reviewTable: {
      total: 'Your Protection Quote Total: ',
      title: 'Description',
      covered: 'Refundable Amount',
    },
  },
  addressForm: {
    labels: {
      city: 'City/Town',
      state: 'Province/Region',
      zip_code: 'Postal Code',
    },
  },
  thankYouPage: {
    header: {
      title: {
        submitted: 'Thank You, Have a Great Day!',
        notSubmitted: 'Thank You. Your Order Has Been Submitted.',
      },
    },
    description:
      'Thank you, ${GLOBAL.customerReducer.customer.first_name}! You will receive a confirmation to ${GLOBAL.customerReducer.customer.email} within 24 hours, once your order has been processed, with your coverage  information and other details. Please ',
  },
  links: {
    helpEmail: 'mailto:help@requestmyrefund.com',
  },
};
/* eslint-enable no-template-curly-in-string */

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { generateHelpLink } from 'utils/helpers';
import { OrdersTypes } from 'utils/translation/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCustomer } from 'store/modules/customer/slice';
import {
  getLastUncompletedStep,
  selectAllStepsComplete,
} from 'store/modules/forms';
import {
  selectOrders,
  selectCustomer,
  selectSelectedItems,
  selectSelectedOrder,
  selectFileUploadConsent,
  selectOrdersAreLoading,
} from 'store/modules/orders/selectors';
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import { FormattedItem, FormattedOrder } from 'store/modules/orders/types';
import {
  setSelectedItemsAndOrder,
  setConsent,
} from 'store/modules/orders/slice';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from 'components/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import HeaderDescription from 'components/HeaderDescription';
import { stepName } from 'globalVariables';
import { useBoolean } from 'react-use';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {
  useDebounce,
  useFilteredOrders,
  useSearchDates,
  useSearchTerm,
} from 'hooks';
import { DateRange } from 'react-date-range';
import { Filters } from 'components/OrdersPage/Filters';
import {
  ColumnFilter,
  DateSearchPanel,
} from 'components/OrdersPage/DateSearchPanel';
import ItemTableWrapper from './ItemTableWrapper';

type DefaultDateValues = { column: ColumnFilter } & DateRange;

const defaultDateValues = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
  column: 'start_date',
};

export const OrdersPage: React.FC<{ pagination?: JSX.Element }> = function ({
  pagination,
}) {
  const history = useHistory();
  const [unique_id, setUnique_id] = useState('');
  const selectedItems = useAppSelector(selectSelectedItems);
  const customer = useAppSelector(selectCustomer(unique_id));
  const selectedOrder = useAppSelector(selectSelectedOrder);
  const orders = useAppSelector(selectOrders);
  const allStepsComplete = useAppSelector(selectAllStepsComplete);
  const lastStep = useAppSelector(getLastUncompletedStep);
  const fileUploadConsent = useAppSelector(selectFileUploadConsent);
  const ordersAreLoading = useAppSelector(selectOrdersAreLoading);
  const type = useAppSelector(selectFlowType);
  const productInUrl = useAppSelector(selectUrlProduct);
  const helpUrl = generateHelpLink(productInUrl);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [userSelection, setUserSelection] = useState({
    items: selectedItems,
    order: selectedOrder,
  });
  const [defaultDates, setDefaultDates] = useState<DefaultDateValues[]>([
    defaultDateValues,
  ]);
  const [searchTerm, setSearchTerm] = useSearchTerm();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchDate, setSearchDate] = useSearchDates();
  const filteredOrders = useFilteredOrders(
    orders,
    debouncedSearchTerm,
    searchDate,
    setDefaultDates
  );
  const [submitting, setSubmitting] = useBoolean(false);
  const dispatch = useAppDispatch();

  const cannotSubmit = userSelection.items.length === 0 || !fileUploadConsent;
  const ordersFound =
    filteredOrders.length > 0 &&
    filteredOrders.some((order) => order.items.length > 0);

  const startClaim = useCallback(() => {
    setSubmitting(true);
    if (userSelection.order) {
      setUnique_id(userSelection.order.uuid);
      dispatch(
        setSelectedItemsAndOrder({
          order: userSelection.order,
          items: userSelection.items,
        })
      );
    }
  }, [dispatch, setSubmitting, userSelection.items, userSelection.order]);
  const updateSelected = useCallback(
    (items: FormattedItem[], order?: FormattedOrder) => {
      setUserSelection((state) => {
        if (items.length === 0) {
          return { items: [], order: undefined };
        }
        if (
          state.order?.created === order?.created ||
          state.order?.created === undefined
        ) {
          return { items, order };
        }
        return state;
      });
    },
    []
  );
  const removeUploadedFilesIfNoConsent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setConsent(event.target.checked));
    },
    [dispatch]
  );
  const handleDateFilterPanelVisibility = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const clearSearchDate = useCallback(() => {
    setSearchDate({
      column: null,
      endDate: null,
      startDate: null,
    });
    setDefaultDates([defaultDateValues]);
  }, [setSearchDate]);

  useEffect(() => {
    if (unique_id) {
      dispatch(setCustomer(customer));
      if (allStepsComplete) {
        history.push(`/${productInUrl}/${type}${lastStep}`);
      } else {
        history.push(`/${productInUrl}/${type}/${stepName}1`);
      }
    }
  }, [
    unique_id,
    customer,
    history,
    allStepsComplete,
    type,
    dispatch,
    productInUrl,
    lastStep,
  ]);

  useEffect(() => {
    updateSelected([], undefined);
  }, [searchTerm, updateSelected]);

  const translation = generateTranslation('orders') as OrdersTypes;

  return (
    <Stack px={2}>
      <HeaderDescription title={translation.header.title}>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 'bold',
            margin: '10px 0 20px 0',
          }}
        >
          {translation.header.row1}
        </Typography>
        <Typography>{translation.header.row2}</Typography>
        <Box mt={2} mb={4}>
          <Typography>{translation.rules.title}</Typography>
          <Typography>{translation.rules.row1}</Typography>
          <Typography>{translation.rules.row2}</Typography>
        </Box>
      </HeaderDescription>
      <Box className="text-center">
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          display="flex"
          justifyContent="space-evenly"
          mb={3}
        >
          <Button
            component={Link}
            variant="outlined"
            className="btn-style font-weight-bold"
            target="_blank"
            href={`/${productInUrl}/certificate/orders?registered=true`}
            sx={{
              marginBottom: { xs: '0.8rem !important', sm: 0 },
            }}
          >
            {translation.links.newFlow}
          </Button>
          <Button
            component={Link}
            variant="outlined"
            className="btn-style font-weight-bold"
            target="_blank"
            href={helpUrl}
          >
            {translation.links.help}
          </Button>
        </Stack>
        <Filters
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleDateFilterPanelVisibility={handleDateFilterPanelVisibility}
          clicked={Boolean(anchorEl)}
          searchDate={searchDate}
          clearSearchDate={clearSearchDate}
        />
        {ordersFound ? (
          <>
            {filteredOrders.map((order, index) => {
              if (order.items.length === 0) return null;

              const isDisabled =
                userSelection.order?.created !== undefined &&
                userSelection.order?.created !== order.created;
              return (
                <Stack key={`stack-${index}`}>
                  <DateSearchPanel
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    translation={translation}
                    setSearchDate={setSearchDate}
                    clearSearchDate={clearSearchDate}
                    defaultDates={defaultDates}
                  />
                  <ItemTableWrapper
                    key={`${order.uuid}-${index}`}
                    isDisabled={isDisabled}
                    order={order}
                    items={order.items}
                    selected={isDisabled ? [] : userSelection.items}
                    updateSelected={updateSelected}
                  />
                </Stack>
              );
            })}
            <Stack alignItems="center" className="text-left">
              {filteredOrders.some((order) => order.items.length > 0) && (
                <FormControlLabel
                  sx={{
                    pt: 1,
                    mt: 3,
                    mr: 0,
                    color: 'grey.600',
                  }}
                  control={
                    <Checkbox
                      className="notranslate"
                      checked={fileUploadConsent}
                      onChange={removeUploadedFilesIfNoConsent}
                      data-testid="consent-checkbox"
                    />
                  }
                  label={translation.consentText}
                  className="translate"
                />
              )}

              <Box mt={3} className="text-center" width="100%">
                <LoadingButton
                  variant="contained"
                  disabled={cannotSubmit || submitting}
                  onClick={startClaim}
                  loading={submitting}
                  className="btn-style"
                >
                  {allStepsComplete ? 'Update' : translation.button}
                </LoadingButton>
              </Box>
            </Stack>
          </>
        ) : (
          !ordersAreLoading && (
            <DateSearchPanel
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              translation={translation}
              clearSearchDate={clearSearchDate}
              setSearchDate={setSearchDate}
              defaultDates={defaultDates}
            >
              <Box className="text-center">{translation.noOrders}</Box>
            </DateSearchPanel>
          )
        )}
        {!ordersFound && ordersAreLoading && (
          <CircularProgress
            size={25}
            thickness={5}
            sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
          />
        )}
      </Box>
    </Stack>
  );
};
